<template>
    <div class="pagebox">
        <!-- 顶部 -->
        <PHeader></PHeader>

        <!-- 志愿填报系统对比分析 -->
        <div class="contrast_box">
            <div class="contrast_con">
                <div class="title">志愿填报系统对比分析</div>
                <div class="type">
                    <div class="tt"><span class="c">{{ProductAlias.WCF}}</span></div>
                    <div class="vs"><span class="v">V</span><span class="s">S</span></div>
                    <div class="tt"><span class="d">{{ProductAlias.WDF}}</span></div>
                    <div class="clear"></div>
                </div>
                <div class="contents">
                    <div class="con l">
                        <div class="mark wow fadeInLeft" data-wow-duration="0.8s" data-wow-delay="0s" data-wow-offset="0"><span>近三年的大学、专业历史分数和位次（排名）</span></div>
                        <div class="mark wow fadeInLeft" data-wow-duration="0.8s" data-wow-delay="0s" data-wow-offset="0"><span>根据三年的位次进行加权算法，自动推荐录取概率</span></div>
                        <div class="mark wow fadeInLeft" data-wow-duration="0.8s" data-wow-delay="0s" data-wow-offset="0"><span class="lang">通过冲、稳、保梯度配置和专业服从调剂确保大学录取，但不能确保专业录取</span></div>
                        <div class="mark wow fadeInLeft" data-wow-duration="0.8s" data-wow-delay="0s" data-wow-offset="0"><span class="lang">{{ProductAlias.WCF}} : 填报985高校和211高校一般误差4-8分二本普通高校和专科高校预测误差8-15分</span></div>
                        <div class="mark wow fadeInLeft" data-wow-duration="0.8s" data-wow-delay="0s" data-wow-offset="0"><span>满意度较低,一般在30%-50%</span></div>
                        <div class="mark wow fadeInLeft" data-wow-duration="0.8s" data-wow-delay="0s" data-wow-offset="0"><span>70%的浪费分数在5-8分以上</span></div>
                    </div>
                    <div class="con m">
                        <div class="name wow fadeIn" data-wow-duration="0.8s" data-wow-delay="0s" data-wow-offset="0"><i class="iconfont left">&#xe609;</i><span>参考依据</span><i class="iconfont right">&#xe609;</i><div class="clear"></div></div>
                        <div class="name wow fadeIn" data-wow-duration="0.8s" data-wow-delay="0s" data-wow-offset="0"><i class="iconfont left">&#xe609;</i><span>计算方法</span><i class="iconfont right">&#xe609;</i><div class="clear"></div></div>
                        <div class="name wow fadeIn" data-wow-duration="0.8s" data-wow-delay="0s" data-wow-offset="0"><i class="iconfont left">&#xe609;</i><span>填报方法</span><i class="iconfont right">&#xe609;</i><div class="clear"></div></div>
                        <div class="name wow fadeIn" data-wow-duration="0.8s" data-wow-delay="0s" data-wow-offset="0"><i class="iconfont left">&#xe609;</i><span>预测误差</span><i class="iconfont right">&#xe609;</i><div class="clear"></div></div>
                        <div class="name wow fadeIn" data-wow-duration="0.8s" data-wow-delay="0s" data-wow-offset="0"><i class="iconfont left">&#xe609;</i><span>录取结果</span><i class="iconfont right">&#xe609;</i><div class="clear"></div></div>
                        <div class="name wow fadeIn" data-wow-duration="0.8s" data-wow-delay="0s" data-wow-offset="0"><i class="iconfont left">&#xe609;</i><span>浪费分数</span><i class="iconfont right">&#xe609;</i><div class="clear"></div></div>
                    </div>
                    <div class="con r">
                        <div class="mark wow fadeInRight" data-wow-duration="0.8s" data-wow-delay="0s" data-wow-offset="0"><span>参考大学和专业的历史分数在当年招生实际录取人数的占位点</span></div>
                        <div class="mark wow fadeInRight" data-wow-duration="0.8s" data-wow-delay="0s" data-wow-offset="0"><span class="lang">采取数学建模，修正了招生总计划数的变化及大学和专业冷热度指数，计算机自动计算专业的预测分数线</span></div>
                        <div class="mark wow fadeInRight" data-wow-duration="0.8s" data-wow-delay="0s" data-wow-offset="0"><span class="lang">按照职业规划匹配相关的专业，按照专业并列配置矩阵部署，确保专业录取</span></div>
                        <div class="mark wow fadeInRight" data-wow-duration="0.8s" data-wow-delay="0s" data-wow-offset="0"><span class="lang">{{ProductAlias.WDF}}：填报985高校和211高校一般误差2-4分，二本普通高校和专科高校预测误差4-6分</span></div>
                        <div class="mark wow fadeInRight" data-wow-duration="0.8s" data-wow-delay="0s" data-wow-offset="0"><span>满意度较高，一般在70%-90%</span></div>
                        <div class="mark wow fadeInRight" data-wow-duration="0.8s" data-wow-delay="0s" data-wow-offset="0"><span>90%的不浪费分数,擦线录取</span></div>
                    </div>
                    <div class="clear"></div>
                </div>
            </div>
        </div>

        <!-- 底部 -->
        <PFooter></PFooter>
    </div>
</template>
<script>
import PHeader from "@/components/Header.vue";
import PFooter from "@/components/Footer.vue";
import {WOW} from 'wowjs';
import { getStore } from "@/utils/utils";
import 'wowjs/css/libs/animate.css';
export default{
    components:{
        PHeader,
        PFooter
    },
    data(){
        return{
            ProductAlias:{}
        }
    },
    mounted(){
        let palias = getStore('ProductAlias');
        if(palias!=null){
            this.ProductAlias = JSON.parse(palias);
        }
        new WOW({live:false}).init();//动画初始化
    },
}
</script>
<style lang="less" scoped>
.contrast_box{
    max-width: 1420px;
    margin: auto;
    .contrast_con{
        margin: 30px 20px 30px;
        background-color: #fff;
        padding-top: 50px;
        .title{
            color: #666;
            font-size: 34px;
            text-align: center;
        }
        .type{
            height: 100px;
            line-height: 100px;
            margin-top: 40px;
            .tt{
                width: calc(50% - 50px);
                float: left;
                height: 100%;
                font-size: 46px;
                font-weight: bold;
                color: rgb(var(--themecolor));
                .c{
                    float: right;
                    margin-right: 48px;
                }
                .d{
                    float: left;
                    margin-left: 48px;
                }
            }
            .vs{
                width: 100px;
                float: left;
                height: 100%;
                font-size: 50px;
                color: #e6a10b;
                text-align: center;
                font-weight: bold;
                font-style:italic;
                .v{
                    line-height: 70px;
                    display: block;
                    float: left;
                    margin-left: 13px;
                }
                .s{
                    line-height: 110px;
                    display: block;
                    float: left;
                }
            }
        }
        .contents{
            max-width: 1200px;
            min-width: 1032px;
            margin: auto;
            padding-bottom: 30px;
            .con{
                float: left;
                .mark{
                    margin: 30px 30px;
                    height: 80px;
                    line-height: 24px;
                    background-color:rgba(var(--themecolor),0.3);
                    color: #444;
                    font-size: 15px;
                    border-radius: 5px;
                    span{
                        display: block;
                        padding: 26px 20px 0px;
                    }
                    span.lang{
                        padding: 16px 20px 0px;
                    }
                }
            }
            .con.l,.con.r{
                width: calc(50% - 70px);

            }
            .con.l .mark{
                text-align: right;
            }
            .con.m{
                width: 140px;
                user-select: none;
                .name{
                    width: 100%;
                    height: 70px;
                    margin: 30px auto;
                    padding-top: 10px;
                    i.left{
                        width: 30px;
                        font-size: 22px;
                        color: #b9b9b9;
                        float: left;
                        display: block;
                        transform: rotate(0deg);
                        margin-top: 17px;
                    }
                    i.right{
                        width: 30px;
                        font-size: 22px;
                        color: #b9b9b9;
                        float: right;
                        display: block;
                        transform: rotate(180deg);
                        margin-top: 15px;
                    }
                    span{
                        color: #fff;
                        font-size: 16px;
                        font-weight: bold;
                        width: 50px;
                        height: 54px;
                        padding-top: 6px;
                        padding-left: 10px;
                        letter-spacing: 8px;
                        line-height: 24px;
                        display: block;
                        float: left;
                        margin-left: 10px;
                        border-radius: 5px;
                        background-color: rgba(var(--themecolor),0.8);
                    }
                }
            }
        }
    }
}
</style>